// seatmap view for ios

import { useEffect, useState } from "react"
import SeatMap from "../SeatMap/SeatMap"
import SeatMapTevo from "../SeatMap/SeatMapTevo"
import { useParams } from "react-router-dom"
import styles from "./SeatmapView.module.scss"
import SeatMap3d from "../SeatMap/SeatMap3d"
import classNames from "classnames"

// need to display the seatmaps based on an event id and section id
// support 3d view if available and be able to toggle in between

const SeatmapView = () => {
  const [event, setEvent] = useState()
  const [ticketGroup, setTicketGroup] = useState()
  const [show3dMap, setShow3dMap] = useState(true)
  const [minimizeMap, setMinimizeMap] = useState(false) // minimizes the 2d map

  const [loaded, setLoaded] = useState(false)
  const params = useParams()

  useEffect(() => {
    getEvent()
    getTicketGroup()
  }, [])

  useEffect(() => {
    if (!!event && !!ticketGroup) {
      setLoaded(true)
    }
  }, [event, ticketGroup])

  useEffect(() => {
    if (!minimizeMap) {
      setMinimizeMap(true)
    }
  }, [show3dMap])

  let headers = {
    "Content-Type": "application/json",
  }

  const getEvent = async () => {
    try {
      const endpoint = "/api/stagehand/event"
      const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

      const response = await fetch(url, {
        method: "post",
        headers,
        body: JSON.stringify({ event_id: params.event_id }),
      }).then((jsonresponse) => {
        return jsonresponse.json()
      })

      setEvent(response)
    } catch (err) {
      console.log(err)
    }
  }

  const getTicketGroup = async () => {
    try {
      const endpoint = "/api/stagehand/live-ticketgroup-by-id"
      const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`
      const response = await fetch(url, {
        method: "post",
        headers,
        body: JSON.stringify({
          ticket_group_uvid: params.section_id,
          event_id: params.event_id,
        }),
      }).then((jsonresponse) => {
        return jsonresponse.json()
      })
      setTicketGroup(response.ticket_group)
    } catch (err) {
      console.log(err)
    }
  }

  let mapVendor, mapConfig
  if (event?.seatmap_id_3ddv) {
    mapVendor = "3ddv"
    mapConfig = {
      venueId: event.seatmap_id_3ddv,
    }
  } else if (event?.tevo_venue_id && event?.tevo_venue_config_id) {
    mapVendor = "tevo"
    mapConfig = {
      venueId: event?.tevo_venue_id,
      configurationId: event?.tevo_venue_config_id,
    }
  }

  console.log(show3dMap, "show 3d map", ticketGroup, ticketGroup?.section.name)

  //   if (!event && !ticketGroup) return null
  if (!loaded) return null
  return (
    <div className={styles.pageContainer}>
      {/* {show3dMap && ( */}
      <div
        className={classNames(
          styles.container3d,
          !minimizeMap && styles.minimized,
          (mapVendor === "tevo" || !show3dMap) && styles.hideMap
        )}
        onClick={() => {
          !minimizeMap && setMinimizeMap(true)
        }}
      >
        <SeatMap3d
          hideMapButton={true}
          className={styles.iosSeatmap}
          config={mapConfig}
          translationRules={event?.translations_3ddv}
          ticketGroup={ticketGroup?.section.name}
          setShow3dMap={setShow3dMap}
          setMinimizeMap={setMinimizeMap}
        />
      </div>
      {/* )} */}
      <div
        className={classNames(
          styles.container,
          mapVendor === "3ddv" && show3dMap && minimizeMap && styles.minimized
        )}
        onClick={() => {
          minimizeMap && setMinimizeMap(false)
        }}
      >
        {mapVendor === "3ddv" && (
          <SeatMap
            config={mapConfig}
            ticketGroups={[ticketGroup]}
            zoomedTicketGroup={ticketGroup}
            highlightedSections={[ticketGroup?.section?.name]}
            handleSectionHighlight={() => {}}
            handleSectionThumbLoaded={() => {}}
            translationRules={event?.translations_3ddv}
          />
        )}
        {mapVendor === "tevo" && (
          <SeatMapTevo
            config={mapConfig}
            ticketGroups={[ticketGroup]}
            zoomedTicketGroup={ticketGroup}
            highlightedSections={[]}
            handleSectionHighlight={() => {}}
            handleSectionThumbLoaded={() => {}}
            translationRules={event?.translations_3ddv}
          />
        )}
      </div>
    </div>
  )
}

export default SeatmapView
